import React from 'react';
import PropTypes from 'prop-types';

// TODO - use source svg instead of <img> for better behavior.
const IconToggle = ({
  assetUntoggled,
  assetToggled = '/static/icons/close-header-main-new.svg',
  classToggled = '',
  classUntoggled = '',
  altToggled = '',
  altUntoggled = '',
  label = '',
  onClick = () => {},
  onKeyDown = () => {},
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  toggled = false,
  className = '',
  width,
  height,
  dataTest = null,
}) => (
  <a
    className={`icon-toggle ${className} ${toggled ? 'toggled' : ''}`}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onClick={onClick}
    onKeyDown={onKeyDown}
    title={toggled ? altToggled : altUntoggled}
    tabIndex={0}
    data-test={dataTest}
  >
    {label}
    <img
      className={toggled ? classToggled : classUntoggled}
      src={toggled ? assetToggled : assetUntoggled}
      alt={toggled ? altToggled : altUntoggled}
      width={width}
      height={height}
    />
  </a>
);

IconToggle.propTypes = {
  assetUntoggled: PropTypes.string.isRequired,
  assetToggled: PropTypes.string,
  classToggled: PropTypes.string,
  classUntoggled: PropTypes.string,
  // Alt text needed for accessibility depending on what is displayed
  altToggled: PropTypes.string,
  altUntoggled: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  toggled: PropTypes.bool,
  label: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  dataTest: PropTypes.string,
};

export default IconToggle;
