import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../../01_atoms/Link';
import Button from '../../../01_atoms/Button';
import IconToggle from '../../../01_atoms/IconToggle';

const imageURL = '/static/icons/navigation-chevron-down-desktop.svg';

class HeaderMenuDesktopFull extends React.Component {
  constructor(props) {
    super(props);

    this.menuToggleOnClick = this.menuToggleOnClick.bind(this);
    this.menuToggleOnKeyDown = this.menuToggleOnKeyDown.bind(this);
  }

  menuToggleOnClick(toggledNavRef) {
    const toggledRemains = { ...this.props.toggled };
    Object.keys(toggledRemains).forEach((key) => {
      if (key !== toggledNavRef) {
        toggledRemains[key] = false;
      }
    });
    toggledRemains[toggledNavRef] = !toggledRemains[toggledNavRef];
    this.props.onMenuToggle(toggledRemains);
  }

  menuToggleOnKeyDown(toggledNavRef) {
    const enter = 13;
    if (event.keyCode === enter) {
      const toggledRemains = { ...this.props.toggled };
      Object.keys(toggledRemains).forEach((key) => {
        if (key !== toggledNavRef) {
          toggledRemains[key] = false;
        }
      });
      toggledRemains[toggledNavRef] = !toggledRemains[toggledNavRef];
      this.props.onMenuToggle(toggledRemains);

      // Focus first link in the opened menu.
      setTimeout(() => {
        const element = this.props.dropdownRef.current.querySelector(
          '.opened-desktop-menu .header-dropdown-desktop--link',
        );
        if (element) {
          element.focus();
        }
      }, 100);
    }
  }

  render() {
    const { contents, toggled, topLinks, primaryCTA, searchToggleOnClick, searchToggleOnKeyDown } =
      this.props;
    return (
      <>
        {topLinks.length > 0 && (
          <nav className="header-toplinks d-flex justify-content-end" role="navigation">
            {topLinks.map((link) => (
              <mark key={`toplink-${link.label}`}>
                <Link {...link.nextLink}>
                  <a>{link.label}</a>
                </Link>
              </mark>
            ))}
          </nav>
        )}

        <div className="header-menu">
          <nav className="left" role="navigation" aria-label="Main menu">
            {contents.map((nav) => {
              const toggledNavRef = `navigation-${nav.slug}`;
              const toggledState = toggled[toggledNavRef];

              return nav.columns.length ? (
                <IconToggle
                  key={toggledNavRef}
                  className="dropdown header-menu--link header-menu-desktop--link"
                  assetToggled={imageURL}
                  assetUntoggled={imageURL}
                  width={16}
                  height={9}
                  toggled={toggledState}
                  label={nav.label}
                  onClick={() => this.menuToggleOnClick(toggledNavRef)}
                  onKeyDown={(event) => this.menuToggleOnKeyDown(toggledNavRef)}
                />
              ) : (
                <Link {...nav.nextLink}>
                  <a className="icon-toggle dropdown header-menu--link header-menu-desktop--link">
                    {nav.slug}
                  </a>
                </Link>
              );
            })}
          </nav>

          <div className="right">
            <IconToggle
              // Header organism component uses header-menu-desktop-search--link classname to focus the icon.
              className="search header-menu-search--link header-menu-desktop-search--link"
              assetUntoggled="/static/icons/knowledge-hub-search.svg"
              toggled={toggled.search}
              altToggled="Close search"
              altUntoggled="Open search"
              width={20}
              height={20}
              onClick={searchToggleOnClick}
              onKeyDown={searchToggleOnKeyDown}
              dataTest="header-menu-search-link"
            />

            {primaryCTA && primaryCTA.label && primaryCTA.nextLink && (
              <Link {...primaryCTA.nextLink}>
                <Button
                  tag="button"
                  href={primaryCTA.nextLink.url}
                  className="header-menu--button header-menu-desktop--button"
                >
                  {primaryCTA.label}
                </Button>
              </Link>
            )}
          </div>
        </div>
      </>
    );
  }
}

HeaderMenuDesktopFull.propTypes = {
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      label: PropTypes.string,
      nextLink: PropTypes.shape({
        url: PropTypes.string,
        href: PropTypes.string,
        as: PropTypes.string,
      }),
    }),
  ).isRequired,
  toggled: PropTypes.shape({
    navigation: PropTypes.bool,
    search: PropTypes.bool,
  }).isRequired,
  onMenuToggle: PropTypes.func,
  topLinks: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      nextLink: PropTypes.shape({
        url: PropTypes.string,
        href: PropTypes.string,
        as: PropTypes.string,
      }),
    }),
  ),
  primaryCTA: PropTypes.shape({
    label: PropTypes.string,
    nextLink: PropTypes.shape({
      url: PropTypes.string,
      href: PropTypes.string,
      as: PropTypes.string,
    }),
  }),
  dropdownRef: PropTypes.object.isRequired,
  searchToggleOnClick: PropTypes.func.isRequired,
  searchToggleOnKeyDown: PropTypes.func.isRequired,
};

HeaderMenuDesktopFull.defaultProps = {
  onMenuToggle: () => {},
  topLinks: [],
  primaryCTA: null,
};

export default HeaderMenuDesktopFull;
