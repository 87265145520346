import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../../01_atoms/Link';
import Button from '../../../01_atoms/Button';
import IconToggle from '../../../01_atoms/IconToggle';

const HeaderMenuMobileFull = ({
  toggled,
  onMenuToggle = () => {},
  primaryCTA = null,
  searchToggleOnClick,
}) => (
  <div className="header-menu">
    <IconToggle
      className="search header-menu-search--link"
      assetUntoggled="/static/icons/knowledge-hub-search.svg"
      altToggled="Close search"
      altUntoggled="Open search"
      width={25}
      height={25}
      toggled={toggled.search}
      onClick={searchToggleOnClick}
    />

    <IconToggle
      className="navigation"
      assetUntoggled="/static/icons/stacked-group.svg"
      altToggled="Close menu"
      altUntoggled="Open menu"
      width={25}
      height={25}
      toggled={toggled.navigation}
      onClick={() => {
        const toggledRemains = { ...toggled };

        Object.keys(toggledRemains).forEach((key) => {
          toggledRemains[key] = false;
        });
        onMenuToggle({
          ...toggledRemains,
          navigation: !toggled.navigation,
        });
      }}
    />

    {primaryCTA && primaryCTA.label && primaryCTA.nextLink && (
      <Link {...primaryCTA.nextLink}>
        <Button
          tag="a"
          href={primaryCTA.nextLink.url}
          className="header-menu--button header-menu-mobile--button"
        >
          Donate
        </Button>
      </Link>
    )}
  </div>
);

HeaderMenuMobileFull.propTypes = {
  toggled: PropTypes.shape({
    navigation: PropTypes.bool,
    search: PropTypes.bool,
  }).isRequired,
  onMenuToggle: PropTypes.func,
  primaryCTA: PropTypes.shape({
    label: PropTypes.string,
    nextLink: PropTypes.shape({
      url: PropTypes.string,
      href: PropTypes.string,
      as: PropTypes.string,
    }),
  }),
  searchToggleOnClick: PropTypes.func.isRequired,
};

export default HeaderMenuMobileFull;
